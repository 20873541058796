const getFullDate = (date: Date) => {
  const dayDate = date.toLocaleDateString(undefined, {
    month: 'long',
    day: 'numeric',
  })
  const dayTime = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit"
  })
  return `${dayDate} ${dayTime}`
}

const isWeekOld = (dateString: string) => {
  const date = new Date(dateString)
  const currentDate = new Date
  const dayLength = 1000 * 60 * 60 * 24
  return currentDate.getUTCMilliseconds() - date.getUTCMilliseconds() > dayLength * 7
}

export {
  getFullDate,
  isWeekOld
}